import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import SurveyArticles from "../components/SurveyComponents/SurveyArticles"

export const query = graphql`
  query surveyTemplates {
    wpcontent {
      surveyTemplates(
        first: 100
        where: { orderby: { field: DATE, order: DESC } }
      ) {
        nodes {
          templateImage
          icon
          excerpt
          content
          title
          uri
          id
          slug
        }
      }
    }
  }
`

function surveyTemplate({ data }) {
  const surveyTemplates = data.wpcontent.surveyTemplates.nodes

  return (
    <Layout>
      <section className="bg-purple-100 mx-auto py-10 pb-0 sm:py-20 sm:pb-0 md:py-10 relative xl:flex overflow-hidden sur-temp-hero">
        <div className="container max-w-7xl sm:text-center px-6 xl:grid grid-cols-2 justify-center items-center">
          <div className="relative">
            <div className="w-20 h-20 rounded-full bg-purple-tagColor absolute right-0 top-0 transform -translate-y-36 hidden md:block" />
            <p className="text-center md:text-left">SURVEY TEMPLATE</p>
            <h1 className="text-4xl xl:text-7xl text-center xl:text-left lg:pr-3 xl:w-10/12">
              Try our free <br />
              <span className="text-orange">survey template</span>
            </h1>
            <p className="my-8 text-lg xl:text-xl xl:w-10/12 text-center xl:text-left text-purple-lightpurple">
              Choose from Employee Engagement survey templates, Satisfaction
              survey template, Employee Net Promoter Score template and many
              more templates designed by us.
            </p>
          </div>
          <div className="w-full flex justify-end relative">
            <img
              className="transform translate-y-10 md:translate-y-28 xl:translate-y-10 flex justify-center xl:justify-end items-center md:h-full z-0 mt-6 xl:mt-0 lg:ml-3 px-3"
              decoding="async"
              loading="lazy"
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/08/VC-Pulse-template-listinghero-2.png"
              alt="hero pic"
            />{" "}
          </div>
        </div>
      </section>
      <section className="sur-temp-posts container px-6 lg:px-0 max-w-7xl mx-auto py-5 sm:py-10 md:pt-10 md:-mt-0 flex flex-col justify-center items-center">
        <div className="grid md:grid-cols-3 gap-x-8 gap-y-4">
          {surveyTemplates.map((template, index) => {
            return <SurveyArticles template={template} key={index} />
          })}
        </div>
      </section>
    </Layout>
  )
}

export default surveyTemplate
